import {IUser, IUserSettings, TSexes} from '@ellen/user-be'

/**
 * Class that implements {@link IUser} interface.
 * Application user, typically considered as family member
 */
export class User implements IUser {
  public id: string = ''
  public familyId: string = ''
  public name: string = ''
  public avatar?: string
  public avatarUrl?: string
  public phoneNumber: string = ''
  public email: string = ''
  public calories = 0
  public version: number = 0
  public settings: IUserSettings = {
    sex: null,
    age: null,
    activityLevel: 2,
    weight: null,
    height: null,
    foodDiets: [],
    allergies: [],
    intolerances: [],
    dislikes: []
  }
  public editable: boolean = false

  constructor(input: Partial<IUser> = {}) {
    // Copy input object, otherwise, when doing "delete" if someone still uses
    // that "input" variable it will be broken
    const inputCopy = JSON.parse(JSON.stringify(input))
    // Assign first object-properties so it uses default values for unset values
    Object.assign(this.settings, inputCopy.settings)
    // Now remove those object-properties from input and assign the rest
    delete inputCopy.settings
    Object.assign(this, inputCopy)
    this.setCalories()
    this.editable = input.type !== 'guest'
  }

  get isValid() {
    return !!this.name &&
      !!this.settings.sex &&
      !!this.settings.age &&
      !!this.settings.activityLevel &&
      !!this.settings.weight &&
      !!this.settings.height
  }

  get sex(): TSexes | null {
    return this.settings.sex
  }

  set sex(sex: TSexes | null) {
    this.settings.sex = sex
    this.setCalories()
  }

  get age(): number | null {
    return this.settings.age
  }

  set age(age: number) {
    this.settings.age = age
    this.setCalories()
  }

  get height(): number | null {
    return this.settings.height
  }

  set height(height: number) {
    this.settings.height = height
    this.setCalories()
  }

  get weight(): number | null {
    return this.settings.weight
  }

  set weight(weight: number) {
    this.settings.weight = weight
    this.setCalories()
  }

  get activityLevel(): number {
    return this.settings.activityLevel!
  }

  set activityLevel(activityLevel: number) {
    this.settings.activityLevel = activityLevel
    this.setCalories()
  }

  /**
   * We are using this equation to calculate per-day calories intake:
   *   https://reference.medscape.com/calculator/846/mifflin-st-jeor-equation
   *   Females: (10*weight [kg]) + (6.25*height [cm]) – (5*age [years]) – 161
   *   Males: (10*weight [kg]) + (6.25*height [cm]) – (5*age [years]) + 5
   *   Guest: All family members calculations / family members = Average of fam.
   *
   *   Multiply by scale factor for activity level:
   *   Sedentary -> *1.2 => Not in use right now - 05-09-2024
   *   Lightly active -> *1.375 => Activity level 1
   *   Moderately active -> *1.55 => Activity level 2
   *   Active -> *1.725 => Activity level 3
   *   Very active ->  *1.9 => Not in use right now - 05-09-2024
   * Then we will divide that intake into 3 different meals (equally big for
   * easy-calculation purposes)
   */

  private setCalories(): void {
    if (!this.isValid) {
      return
    }
    const ourActivityToMifflinMap: Record<number, number> = {
      1: 1.375,
      2: 1.55,
      3: 1.725
    }

    const activityFactor = ourActivityToMifflinMap[this.activityLevel]

    this.calories = Math.round((
      10 * this.weight! +
      6.25 * this.height! -
      5 * this.age! +
      (this.sex === 'male' ? 5 : -161)
    ) * activityFactor)
  }
}

