import {inject, Injectable, signal} from '@angular/core'
import {Router} from '@angular/router'
import {BehaviorSubject, filter, first, from, Observable, of, race, Subject, switchMap, tap, timer} from 'rxjs'
import {LOCAL_STORAGE} from '../application/localstorage.provider'
import {UserService} from './user.service'
import {environment} from '../../environments/environment'
import {HttpClient} from '@angular/common/http'
import {DetailedFamily} from '../utils/detailed-family.class'
import {IEllenUser} from 'ellen-bankid-gw/src/functions/public-types'
import {ICollectResult} from 'ellen-bankid-gw'
import {map} from 'rxjs/operators'

const ACCESS_TOKEN_NAME = 'joshu-token'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /**
   * Signal-flag that marks if the app is doing its initial loading or not
   */
  public isLoading$ = signal<boolean>(false)
  /**
   * Signal that keeps track of the current logged user. It also can be null,
   * meaning there is no logged user.
   */
  public loggedInUser$ = signal<IEllenUser | null>(null)

  /**
   * True if we can change user
   */
  public impersonator$ = signal<boolean>(false)

  /**
   * True if we are in impersonated mode.
   */
  public impersonated$ = signal<boolean>(false)

  /**
   * Emit here to cancel impersonation
   */
  public impersonatedUser$ = new Subject<boolean>()
  /**
   * Observable that keeps track of the access token once a user is logged in.
   * If nobody is logged in, its value will be null.
   */
  public accessToken$: Observable<ICollectResult | null>


  /**
   * Behaviour subject related to observable {@link accessToken$}
   * @private
   */
  private pAccessToken$ = new BehaviorSubject<ICollectResult | null>(null)

  private ils = inject(LOCAL_STORAGE)
  private router = inject(Router)
  private userService = inject(UserService)
  private http = inject(HttpClient)

  constructor() {
    this.accessToken$ = this.pAccessToken$.asObservable()
  }

  /**
   * Method that should be called as APP_INITIALIZER that will check if there
   * is an access token already stored in local storage, which will prevent
   * the need to re-login to the user. It's automatically logged in.
   * This is good to be done at the very beginning of the app, before anything
   * else.
   */
  public bootstrap(): Observable<any> {
    const accessToken = this.ils.getItem(ACCESS_TOKEN_NAME) as string

    try {
      this.pAccessToken$.next(JSON.parse(accessToken))
      return this.loadInitialUser(JSON.parse(accessToken))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_e) {
      return of(null)
    }
  }

  private loadInitialUser(token: ICollectResult): Observable<any> {
    if (!token) {
      return from(this.router.navigate(['external']))
    }
    this.isLoading$.set(true)
    // Save access token in local storage
    this.ils.setItem(ACCESS_TOKEN_NAME, JSON.stringify(token))
    return this.self().pipe(
      switchMap((user: IEllenUser) => {
        this.loggedInUser$.set(user)
        this.impersonator$.set(user.scopes.includes('eln:admin:users:impersonate'))
        // Get all users/family information
        return this.userService.getSelf()
      }),
      tap((family: DetailedFamily) => {
        // Set "me" user, which represents logged-in user inside the family
        const user = family.familyMembers
          .find(u => u.id === this.loggedInUser$()?.sub)!
        user.name = user.name || this.loggedInUser$()?.name as string
        this.userService.me$.set(user)
        this.isLoading$.set(false)
      })
    )
  }

  /**
   * Sets access token in the app which will trigger an event to save it
   * in local storage. It will also recover the embedded user.
   * Also, it will trigger the first API call to recover "self" information.
   * @param token
   */
  public login(token: ICollectResult): Observable<any> {
    this.pAccessToken$.next(token)
    return this.loadInitialUser(token)
  }

  /**
   * Logout method that can be called manually by user, or automatically when
   * a 401/403 response is received from API. This last part is done by
   * response.interceptor.ts.
   * All login-related data, like access token or current logged user, will be
   * reset and user will be redirected to initial page.
   */
  public logout() {
    this.ils.removeItem(ACCESS_TOKEN_NAME)
    this.pAccessToken$.next(null)
    this.loggedInUser$.set(null)
    this.userService.reset()
    this.router.navigate(['/', 'external']).then()
  }


  public token(): Observable<ICollectResult> {
    const url = `${environment.bankIdGatewayUrl}/token`
    return this.accessToken$.pipe(
      first(),
      filter(Boolean),
      switchMap((token: ICollectResult) =>
        this.http.put<any>(url, token.refreshToken)),
      tap((token: ICollectResult) => {
        this.pAccessToken$.next(token)
        this.ils.setItem(ACCESS_TOKEN_NAME, JSON.stringify(token))
      })
    )
  }

  /**
   * This is the real _self_
   */
  public self(): Observable<IEllenUser> {
    const url = `${environment.bankIdGatewayUrl}/self`
    return this.http.get<any>(url)
  }

  public impersonate(token: ICollectResult): Observable<any> {
    let original: ICollectResult
    return this.pAccessToken$.pipe(
      filter(Boolean),
      first(),
      switchMap((originalToken: ICollectResult) => {
        this.impersonated$.set(true)
        original = originalToken
        this.restoreLoginTimer(originalToken)
        this.pAccessToken$.next(token)
        return this.loadInitialUser(token)
      }),
      map(() => {
        return original
      })
    )
  }

  /**
   * After 13 minutes we restore the login. the token
   * expires after 15.
   */
  private restoreLoginTimer(token: ICollectResult): void {

    race(timer(60 * 13 * 1000), this.impersonatedUser$)
      .pipe(
        first(),
        switchMap(() => {
          this.impersonated$.set(false)
          this.pAccessToken$.next(token)
          return this.loadInitialUser(token)
        }))
      .subscribe()
  }
}