/**
 * Different type of qualities that a menu-meal can have as array.
 */
export const MenuQualities = ['cheap', 'fancy', 'bbq'];
/**
 * Different type of meals that a menu can have as array.
 */
export const DailyMeals = ['lunch', 'dinner'];
/**
 * Sorting function that will sort two {@link TDay} items.
 */
export const sortByDay = (a, b) => {
  const dayValue = day => {
    return day === 'quick' ? -1 : Number(day);
  };
  return dayValue(a) - dayValue(b);
};
const mealSortingMap = {
  lunch: 0,
  dinner: 1
};
/**
 * Sorting function that will sort two {@link TDailyMeal} items.
 */
export const sortByMeal = (a, b) => {
  return mealSortingMap[a] - mealSortingMap[b];
};
/**
 * Base class that implements {@link IBaseMeals}, including all possible meals,
 * with their generic type, and the day they were assigned/created for.
 */
export class BaseMeals {
  constructor(obj) {
    Object.assign(this, obj);
  }
  get mealValues() {
    return Object.keys(this).filter(key => DailyMeals.includes(key)).sort(sortByMeal).map(dailyMeal => {
      return this[dailyMeal];
    }).filter(Boolean);
  }
}
export class MenuDayConfig extends BaseMeals {}
export class FamilyMenuDay extends BaseMeals {}
/**
 * Data model to use after generating menus with GPT. It represents
 * a recipe created for a specific day and a specific meal.
 * It could contain error, which will invalidate all data inside it.
 */
export class GeneratedRecipe {
  constructor(obj) {
    Object.assign(this, obj);
  }
}
