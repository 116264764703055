export const Languages = ['en', 'se'];
export const OtherTranslations = ['gpt_message_cookbook', 'gpt_message_format_output', 'gpt_message_lang_output', 'gpt_message_generate_image'];
const otherValues = {
  gpt_message_cookbook: {
    en: 'You are a English receipt book',
    se: 'Du är en svensk receptbok'
  },
  gpt_message_format_output: {
    en: 'Format response as JSON with keys title, ingredients and instructions. Ingredients and instructions are arrays of strings. Ingredient have this format "name - quantity"',
    se: 'Formatera svaret som JSON med nycklar: title, ingredients och instructions. Ingredients och instructions är arrays av strings. Ingredienser har detta format "namn - mängd"'
  },
  gpt_message_lang_output: {
    en: 'Output must be in English',
    se: 'Svaret måste vara på svenska'
  },
  gpt_message_generate_image: {
    en: 'Give me a simple description of how this recipe would look like',
    se: 'Give me a simple description of how this recipe would look like'
  }
};
const dailyMealValues = {
  lunch: {
    en: 'Lunch',
    se: 'Lunch'
  },
  dinner: {
    en: 'Dinner',
    se: 'Middag'
  }
};
const menuQualityValues = {
  cheap: {
    en: 'Budget-friendly',
    se: 'Budgetvänlig'
  },
  fancy: {
    en: 'Extra-nice',
    se: 'Guldkant'
  },
  bbq: {
    en: 'Bbq',
    se: 'Bbq'
  }
};
const foodPreferenceValues = {
  vegan: {
    en: 'vegan',
    se: 'Vegan'
  },
  flexitarian: {
    en: 'flexitarian',
    se: 'Flexeterian'
  },
  veganKeto: {
    en: 'Vegan (Keto)',
    se: 'Vegan (Keto)'
  },
  vegetarian: {
    en: 'Vegetarian',
    se: 'Vegetarian'
  },
  vegetarianKeto: {
    en: 'vegetarianKeto',
    se: 'Vegetarian (Keto)'
  },
  pescetarian: {
    en: 'Pescetarian',
    se: 'Pescetarian'
  },
  milk: {
    en: 'Milk',
    se: 'Mjölk'
  },
  nut: {
    en: 'Nut',
    se: 'Nötter'
  },
  shellfish: {
    en: 'Shellfish',
    se: 'Skaldjursallergi'
  },
  egg: {
    en: 'Egg',
    se: 'Äggallergi'
  },
  soy: {
    en: 'Soy',
    se: 'Sojaallergi'
  },
  wheat: {
    en: 'Wheat',
    se: 'Veteallergi'
  },
  sulfates: {
    en: 'Sulfates',
    se: 'Sulfiter'
  },
  lupin: {
    en: 'Lupin',
    se: 'Lupin'
  },
  cereals: {
    en: 'Cereals',
    se: 'Spannmål'
  },
  crustacean: {
    en: 'Crustacean',
    se: 'Kräftdjur'
  },
  fish: {
    en: 'Fish',
    se: 'Fisk'
  },
  peanutSep: {
    en: 'PeanutSep',
    se: 'Jordnöt (sep)'
  },
  celery: {
    en: 'Celery',
    se: 'Selleri'
  },
  mustard: {
    en: 'Mustard',
    se: 'Senap'
  },
  sesameSeed: {
    en: 'SesameSeed',
    se: 'Sesamfrö'
  },
  mollusc: {
    en: 'Mollusc',
    se: 'Blötdjur'
  },
  lactoseIntolerance: {
    en: 'Lactose intolerance',
    se: 'Laktosintolerans'
  },
  glutenIntolerance: {
    en: 'Gluten intolerance',
    se: 'Glutenintolerans'
  },
  glutenSensitivity: {
    en: 'Gluten sensitivity',
    se: 'Glutenkänslighet'
  },
  histamineIntolerance: {
    en: 'Histamine intolerance',
    se: 'Histaminintolerans'
  }
};
const sexValues = {
  male: {
    en: 'Male',
    se: 'Man'
  },
  female: {
    en: 'Female',
    se: 'kvinna'
  }
};
export class Translations {
  static get(value, lang) {
    if (!value) {
      return '';
    }
    return this.translations[value][lang];
  }
}
Translations.translations = {
  ...dailyMealValues,
  ...menuQualityValues,
  ...foodPreferenceValues,
  ...sexValues,
  ...otherValues
};
