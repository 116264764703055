/**
 * All entries of {@link TSexes}
 */
export const Sexes = ['female', 'male'];
/**
 * All entries of {@link TFoodRestriction}
 */
export const FoodRestrictions = ['foodDiets', 'allergies', 'intolerances', 'dislikes'];
/**
 * All entries of {@link TFoodDiet}
 */
export const FoodDiets = ['flexitarian', 'pescetarian', 'vegan', 'veganKeto', 'vegetarian', 'vegetarianKeto'];
/**
 * All entries of {@link TAllergy}
 */
export const Allergies = ['celery', 'cereals', 'crustacean', 'egg', 'fish', 'milk', 'nut', 'lupin', 'mollusc', 'mustard', 'peanutSep', 'shellfish', 'sesameSeed', 'soy', 'sulfates', 'wheat'];
/**
 * All entries of {@link TIntolerance}
 */
export const Intolerances = ['glutenIntolerance', 'glutenSensitivity', 'histamineIntolerance', 'lactoseIntolerance'];
const UserTypes = ['owner', 'member', 'guest'];
